import React, {Component} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Partnerslidermultiple extends Component{
  render(){
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      speed: 3000,
      autoplaySpeed: 3000,
      cssEase: "linear"
    };
    return(
      <div className="mainContainer">
        <h1 className="multiplepartnerslider">Our Corporate Partners</h1>
        <Slider {...settings}>
          <div className="container">
            <img src="https://acr.iitm.ac.in/wp-content/uploads/2024/01/powergridcorp.png" alt=""/>
          </div>
          <div className="container">
            <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/bosch-1.jpg" alt=""/>
          </div>
          <div className="container">
            <img src="https://acr.iitm.ac.in/wp-content/uploads/2024/01/kotak.png" alt=""/>
          </div>
          <div className="container">
            <img src="https://acr.iitm.ac.in/wp-content/uploads/2024/01/ttk.png" alt=""/>
          </div>
          <div className="container">
            <img src="https://acr.iitm.ac.in/wp-content/uploads/2024/01/lttechnology.png" alt=""/>
          </div>
          <div className="container">
            <img src="https://acr.iitm.ac.in/wp-content/uploads/2024/01/htparekh.jpg" alt=""/>
          </div>
          <div className="container">
            <img src="https://acr.iitm.ac.in/wp-content/uploads/2024/01/google.png" alt=""/>
          </div>
          <div className="container">
            <img src="https://acr.iitm.ac.in/wp-content/uploads/2024/01/fedex.png" alt=""/>
          </div>
          <div className="container">
            <img src="https://acr.iitm.ac.in/wp-content/uploads/2024/01/mphasis.jpg" alt=""/>
          </div>
          <div className="container">
            <img src="https://acr.iitm.ac.in/wp-content/uploads/2024/01/americanexpress.png" alt=""/>
          </div>
          <div className="container">
            <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/capgemini.jpg" alt=""/>
          </div>
          <div className="container">
            <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/thales.jpg" alt=""/>
          </div>
          <div className="container">
            <img src="https://acr.iitm.ac.in/wp-content/uploads/2024/01/murugappa.png" alt=""/>
          </div>         
        </Slider>
      </div>
    );
  }
}

export default Partnerslidermultiple;